import React from "react"

export const About = props => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>
                {" "}
                we offer tailored financial solutions to secure your future.
                Specializing in SIPs, mutual funds, health insurance, and
                personalized stock recommendations, we cater to your diverse
                investment needs. Our client-focused approach aims to align your
                financial goals with prudent investment strategies. With a
                commitment to transparency and reliability, we strive to empower
                you on your financial journey. Partner with us to achieve your
                aspirations and navigate the path to financial success.
                Empowering financial security through personalized investments,
                health coverage, and holistic advisory services, serving over a
                thousand clients across multiple cities in India.
              </p>
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>* Personalized Financial Solutions</ul>
                  <ul>* Client-Centric Approach</ul>
                  <ul>* Diverse Expertise</ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>* Transparent Communication</ul>
                  <ul>* Empowerment through Knowledge </ul>
                  <ul>* Trusted Relationships </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
